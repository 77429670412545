import React from "react";

export default function LandingHeader({ firstLine, secondLine }) {
  return (
    <div className="landingheader-container d-flex bg-gradient-blueberry-lavender">
      <section className="container-fluid d-flex">
        <div className="container color-white d-flex flex-column justify-content-center">
          <div className="row d-flex align-items-center">
            <div className="col-12 my-4 main-leyend text-left">
              <h1 className="my-0 color-capry">{firstLine}</h1>
              <h2 className="my-0 color-white">{secondLine}</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
