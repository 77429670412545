/* eslint-disable react/no-unescaped-entities */
import React from "react";

export default function Process() {
  return (
    <div className="container-fluid bg-blueberry color-white pb-4 process-section">
      <div className="container py-5">
        <div className="row mb-5">
          <div className="col text-center">
            <h1 className="uppercase">Process</h1>
            <p>
              Scalable, cost-effective development focused on quality is not an easy feat. Yet, it's the difference between failing or becoming
              the next unicorn startup. At Toptive, we take a thorough approach to all we do so you can focus on growth, not on optimizing your development.
              Our 360 Development Analysis process includes:
            </p>
          </div>
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="col-2 bubble d-flex justify-content-center align-items-center p-0 height-auto text flex-column-reverse">
            <div className="up-line">
              <div className="head bg-capry" />
              <div className="line bg-capry" />
            </div>
            <p>
              Research and
              <br />
              Contextual
              <br />
              Build-out
            </p>
          </div>
          <div className="col-3 bubble d-flex justify-content-center align-items-center p-0 over height-auto" />
          <div className="col-2 bubble d-flex justify-content-center align-items-center p-0 height-auto text flex-column-reverse">
            <div className="up-line">
              <div className="head bg-capry" />
              <div className="line bg-capry" />
            </div>
            <p>
              Process and
              <br />
              Workflow
              <br />
              Deep-dive
            </p>
          </div>
          <div className="col-3 bubble d-flex justify-content-center align-items-center p-0 over double height-auto" />
          <div className="col-2 bubble d-flex justify-content-center align-items-center p-0 height-auto text flex-column-reverse">
            <div className="up-line">
              <div className="head bg-capry" />
              <div className="line bg-capry" />
            </div>
            <p>
              Assessment
              <br />
              Review Call
            </p>
          </div>
        </div>

        <div className="row d-flex flex-row align-items-center justify-content-center">
          <div className="col-2 bg-lavender bubble d-flex justify-content-center align-items-center p-0 dotted">
            01
          </div>
          <div className="col-3 bg-white color-lavender bubble d-flex justify-content-center align-items-center p-0 over hide-overflow">
            02
            <div className="col-8 bg-capry bubble p-0 left-intersec position-absolute white-lines" />
            <div className="col-8 bg-capry bubble p-0 right-intersec position-absolute white-lines" />
          </div>
          <div className="col-2 bg-lavender bubble d-flex justify-content-center align-items-center p-0 dotted">
            03
          </div>
          <div className="col-3 bg-capry bubble d-flex justify-content-center align-items-center p-0 over hide-overflow double">
            04
            <div className="col-8 bg-lavender bubble p-0 left-intersec position-absolute white-lines" />
            <div className="col-8 bg-capry bubble p-0 right-intersec position-absolute white-lines" />
          </div>
          <div className="col-2 bg-lavender bubble d-flex justify-content-center align-items-center p-0 dotted">
            05
          </div>
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="col-2 bubble d-flex justify-content-center align-items-center p-0 height-auto" />
          <div className="col-3 bubble d-flex justify-content-center align-items-center p-0 over height-auto text bottom flex-column">
            <div className="up-line">
              <div className="line bg-capry" />
              <div className="head bg-capry" />
            </div>
            <p>
              1 Hour
              <br />
              Kick-off Call
            </p>
          </div>
          <div className="col-2 bubble d-flex justify-content-center align-items-center p-0 height-auto" />
          <div className="col-3 bubble d-flex justify-content-center align-items-center p-0 over double height-auto text bottom flex-column">
            <div className="up-line">
              <div className="line bg-white" />
              <div className="head bg-white" />
            </div>
            <p>
              Documentation
              <br />
              of Findings
            </p>
          </div>
          <div className="col-2 bubble d-flex justify-content-center align-items-center p-0 height-auto text" />
        </div>
      </div>
    </div>
  );
}
