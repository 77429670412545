/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";
import mesh from "../../assets/images/mesh.svg"
import withFormValidation from "../../utils/withFormValidation";

class AssessmentSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      key: '',
      email: '',
      phone: '',
      company: '',
      success: false,
    };

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCaptchaCompleted = this.handleCaptchaCompleted.bind(this);

    this.validation = props.validation;
  }

  handleCaptchaCompleted(key) {
    this.setState({
      key,
    }, () => {
      if (this.wasSubmitted) {
        this.validation.validate();
      }
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    }, () => {
      if (this.wasSubmitted) {
        this.validation.validate();
      }
    });
  }

  handleOnSubmit(event) {
    event.preventDefault();
    this.wasSubmitted = true;

    if (this.validation.validate()) {
      const { name, email, phone, company, key } = this.state;

      fetch('/.netlify/functions/contact', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ type: 'schedule', name, company, email, phone, key })
      }).then(res => {
          this.setState({
            success: true,
            error: false,
          });
        })
        .catch((e) => {
          console.log('error', e);
          this.setState({
            error: true,
          });
        })
    } else {
      this.validation.focusInvalidElement();
    }
  }

  render() {
    const { name, email, phone, company, key, error, success } = this.state;

    return (
      <section className="container-fluid assessment-schedule-container position-relative">
        <div className="row position-absolute w-100 h-100 py-6">
          <div className="col-12 col-md-6 bg-white mesh-container py-5">
            <img src={mesh} alt="" />
          </div>
          <div className="col-12 col-md-6 bg-gray" />
        </div>

        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-md-6 d-flex align-items-center mb-4 mb-md-0">
              <div className="row">
                <div className="col-12 col-md-10" style={{textAlign: 'justify'}}>
                  <h3 className="uppercase font-weight-bold">
                    Helping You Create the Future of Healthcare
                  </h3>
                    <p>
                      We help companies to create innovative solutions in the healthcare industry using trend technologies with security as a priority.
                    </p>
                    <p>
                      Each year we research and update our <b>HIPAA compliance</b> certifications.
                    </p>
                    <p>
                      Using agile methodologies, from prototyping to QA testing and deployment, we offer to our clients a complete engineering service. </p>
                    <p className="my-0">
                      Our expertise:
                    </p>
                    <ul className="toptive-list">
                      <li className="col-12 my-2 font-weight-bold">Custom healthcare solutions.</li>
                      <li className="col-12 my-2 font-weight-bold">Integration with third-party systems.</li>
                    </ul>
                    <p className="my-0">
                      Technologies we apply:
                    </p>
                    <ul className="toptive-list">
                      <li className="col-12 my-2 font-weight-bold">Big Data.</li>
                      <li className="col-12 my-2 font-weight-bold">AI & Machine Learning.</li>
                      <li className="col-12 my-2 font-weight-bold">Internet of Medical Things (IoMT).</li>
                      <li className="col-12 my-2 font-weight-bold">Telehealth.</li>
                    </ul>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 bg-gray position-static">
              <div className="bg-fix bg-gray d-block d-md-none" />
              <div className="row pt-4 pt-md-0">
                <div className="col-12 col-lg-10 offset-lg-1">

                  {error && (
                    <div className="col-12 alert alert-danger" role="alert">
                      <p className="mb-0">
                        Something went wrong, please try again.
                        <button type="button" className="close" aria-label="Close" onClick={this.clearError}>
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </p>
                    </div>
                  )}

                  {!success && (
                    <form className="row" noValidate onSubmit={this.handleOnSubmit} id="assessmentForm" tabIndex="0" style={{ outline: 'none' }}>
                      <div className="col-12 mb-4">
                        <h1 className="text-center">
                          Schedule Your
                          <br />
                          Free Assessment Today
                        </h1>
                      </div>

                      <div className={classNames("col-12 form-group", { error: !this.validation.isValidElement("name") })}>
                        <input
                          className="form-control big"
                          type="text"
                          placeholder="Full Name *"
                          id="nameInput"
                          name="name"
                          autoComplete="off"
                          onChange={this.handleInputChange}
                          value={name}
                          required
                          ref={input => { this.validation.initElement(input, "name"); }}
                        />
                        {!this.validation.isValidElement("name") && <span className="error-msg">This field is required</span>}
                      </div>
                      <div className={classNames("col-12 form-group", { error: !this.validation.isValidElement("email") })}>
                        <input
                          className="form-control big"
                          type="email"
                          placeholder="Email *"
                          id="emailInput"
                          name="email"
                          autoComplete="off"
                          onChange={this.handleInputChange}
                          value={email}
                          required
                          ref={input => { this.validation.initElement(input, "email"); }}
                        />
                        {!this.validation.isValidElement("email", "valueMissing") && <span className="error-msg">This field is required</span>}
                        {!this.validation.isValidElement("email", "typeMismatch") && <span className="error-msg">Submit a valid email address</span>}
                      </div>
                      <div className="col-12 col-sm-6 form-group">
                        <input
                          className="form-control big"
                          type="tel"
                          placeholder="Phone"
                          id="phoneInput"
                          name="phone"
                          autoComplete="off"
                          value={phone}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-12 col-sm-6 form-group">
                        <input
                          className="form-control big"
                          type="text"
                          placeholder="Company"
                          id="companyInput"
                          name="company"
                          autoComplete="off"
                          value={company}
                          onChange={this.handleInputChange}
                        />
                      </div>

                      <div className="col-12 col-sm-6 form-group">
                        <ReCAPTCHA
                          onChange={this.handleCaptchaCompleted}
                          sitekey="6LeCHa0UAAAAAKTIXiSIHs6x9QYA9lAd_iyxX-j5"
                        />
                        <input
                          type="text"
                          className="form-control"
                          id="keyInput"
                          name="key"
                          hidden
                          defaultValue={key}
                          tabIndex="-1"
                          required
                          ref={input => { this.validation.initElement(input, "key"); }}
                        />
                        {!this.validation.isValidElement("key") && <span className="error-msg">Please complete captcha</span>}
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn btn-capry btn-block uppercase">Send</button>
                      </div>
                    </form>
                  )}

                  {success && (
                    <div className="row my-5">
                      <div className="col-12 text-center mb-4">
                        <h1 className="font-weight-bold uppercase color-capry mb-0">
                          Thank you for
                        </h1>
                        <h1 className="font-weight-bold uppercase">
                          Schedule Your Free Assessment
                        </h1>
                        <h2 className="pt-3 mt-4 text-center">We will be in contact as soon as possible</h2>
                      </div>
                    </div>
                  )}


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withFormValidation(AssessmentSchedule);
