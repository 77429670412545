/* eslint-disable react/no-unescaped-entities */
import React from "react";
import longLegs from "../../assets/images/long-legs.png";

export default function WhatYouGet() {
  return (
    <section className="container-fluid what-you-get-section">
      <div className="row">
        <div className="col-12 col-md-5 col-lg-4 bg-capry robot-legs d-flex flex-row justify-content-center align-items-center" style={{ backgroundImage: `url(${longLegs})`}} />
        <div className="col-12 col-md-7 col-lg-8 py-5">
          <div className="container py-2">
            <h1 className="color-capry font-weight-bold mb-4 uppercase">What you get</h1>
            <ul className="row toptive-list pl-3">
              <li className="col-12 my-2">
                Deep-dive into team setup, quality, procedures, and more
              </li>
              <li className="col-12 my-2">
                Identification and documentation on team inefficiencies, costs, and speed to execution
              </li>
              <li className="col-12 my-2">
                Detailed execution plan to improve your team's speed, quality management, and workflows
              </li>
              <li className="col-12 my-2">
                Documented analysis of costs to improve profitability and investment opportunities
              </li>
              <li className="col-12 my-2">
                Review call with world-class development experts to answer questions and dive deeper
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
