import React from "react";

const focusForm = () => {
  document.getElementById("assessmentForm").focus();
};

export default function ScheduleNotice() {
  return (
    <div className="container-fluid bg-blueberry color-white">
      <div className="container py-5">
        <div className="row">
          <div className="col text-center uppercase">
            <h1 className="mb-4">
              Schedule your free
              <br />
              assessment today
            </h1>
            <button type="button" className="btn btn-lavender" style={{ fontSize: '1.5rem', borderRadius: '2px' }} onClick={focusForm}>
              <i className="fa fa-arrow-up" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
